import { createSlice } from '@reduxjs/toolkit'

export const PRODUCTS_SLICE_NAME = 'products';

const initialState = {
    products: [],
    error: '',
  }

export const productsSlice = createSlice({
  name: PRODUCTS_SLICE_NAME,
  initialState,
  reducers: {
    fetchedProducts: (state = initialState, actions) => {
        // console.log('s:', actions)
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      return {
        ...state,
        products: actions.payload,
      }
    },
    // decrement: (state) => {
    //   state.value -= 1
    // },
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload
    // },
  },
})

// Action creators are generated for each case reducer function
export const { fetchedProducts } = productsSlice.actions

export default productsSlice.reducer