import React, { useEffect, useState, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
// import { multilanguage, loadLanguages } from "redux-multilanguage";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
// import { useGoogleOneTapLogin } from 'react-google-one-tap-login';
import googleOneTap from 'google-one-tap';
import './App.css';
import { fetchedShop } from "./redux2/shopSlice";
import { sliderReducer } from "./redux2/sliderSlice";
import { fetchedLoggedInUser } from "./redux2/authSlice";
import CookieConsent from "./components/cookie/CookieConsent";
import CookiePolicy from "./components/cookie/CookiePolicy";
import Component from "./components/messenger/IntercomMessenger";
// import ShareComponent from "./components/share/Share";
// import DynamicMetaPage from "./components/dynamicMetaPage/DynamicMetaPage";
// import SocialShare from "./components/share/SocialShare";
 
// home pages   
const Search = lazy(() => import("./pages/home/Search"));
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));
const HomeFashionTwo = lazy(() => import("./pages/home/HomeFashionTwo"));
const HomeFashionThree = lazy(() => import("./pages/home/HomeFashionThree"));
const HomeFashionFour = lazy(() => import("./pages/home/HomeFashionFour"));
const HomeFashionFive = lazy(() => import("./pages/home/HomeFashionFive"));
const HomeFashionSix = lazy(() => import("./pages/home/HomeFashionSix"));
const HomeFashionSeven = lazy(() => import("./pages/home/HomeFashionSeven"));
const HomeFashionEight = lazy(() => import("./pages/home/HomeFashionEight"));
const HomeKidsFashion = lazy(() => import("./pages/home/HomeKidsFashion"));
const HomeCosmetics = lazy(() => import("./pages/home/HomeCosmetics"));
const HomeFurniture = lazy(() => import("./pages/home/HomeFurniture"));
const HomeFurnitureTwo = lazy(() => import("./pages/home/HomeFurnitureTwo"));
const HomeFurnitureThree = lazy(() =>
  import("./pages/home/HomeFurnitureThree")
);
const HomeFurnitureFour = lazy(() => import("./pages/home/HomeFurnitureFour"));
const HomeFurnitureFive = lazy(() => import("./pages/home/HomeFurnitureFive"));
const HomeFurnitureSix = lazy(() => import("./pages/home/HomeFurnitureSix"));
const HomeFurnitureSeven = lazy(() =>
  import("./pages/home/HomeFurnitureSeven")
);
const HomeElectronics = lazy(() => import("./pages/home/HomeElectronics"));
const HomeElectronicsTwo = lazy(() =>
  import("./pages/home/HomeElectronicsTwo")
);
const HomeElectronicsThree = lazy(() =>
  import("./pages/home/HomeElectronicsThree")
);
const HomeBookStore = lazy(() => import("./pages/home/HomeBookStore"));
const HomeBookStoreTwo = lazy(() => import("./pages/home/HomeBookStoreTwo"));
const HomePlants = lazy(() => import("./pages/home/HomePlants"));
const HomeFlowerShop = lazy(() => import("./pages/home/HomeFlowerShop"));
const HomeFlowerShopTwo = lazy(() => import("./pages/home/HomeFlowerShopTwo"));
const HomeOrganicFood = lazy(() => import("./pages/home/HomeOrganicFood"));
const HomeOrganicFoodTwo = lazy(() =>
  import("./pages/home/HomeOrganicFoodTwo")
);
const HomeOnepageScroll = lazy(() => import("./pages/home/HomeOnepageScroll"));
const HomeGridBanner = lazy(() => import("./pages/home/HomeGridBanner"));
const HomeAutoParts = lazy(() => import("./pages/home/HomeAutoParts"));
const HomeCakeShop = lazy(() => import("./pages/home/HomeCakeShop"));
const HomeHandmade = lazy(() => import("./pages/home/HomeHandmade"));
const HomePetFood = lazy(() => import("./pages/home/HomePetFood"));
const HomeMedicalEquipment = lazy(() =>
  import("./pages/home/HomeMedicalEquipment")
);
const HomeChristmas = lazy(() => import("./pages/home/HomeChristmas"));
const HomeBlackFriday = lazy(() => import("./pages/home/HomeBlackFriday"));
const HomeBlackFridayTwo = lazy(() =>
  import("./pages/home/HomeBlackFridayTwo")
);
const HomeValentinesDay = lazy(() => import("./pages/home/HomeValentinesDay"));

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const ShopGridFilter = lazy(() => import("./pages/shop/ShopGridFilter"));
const ShopGridTwoColumn = lazy(() => import("./pages/shop/ShopGridTwoColumn"));
const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));
const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
const ShopGridRightSidebar = lazy(() =>
  import("./pages/shop/ShopGridRightSidebar")
);
const ShopListStandard = lazy(() => import("./pages/shop/ShopListStandard"));
const ShopListFullWidth = lazy(() => import("./pages/shop/ShopListFullWidth"));
const ShopListTwoColumn = lazy(() => import("./pages/shop/ShopListTwoColumn"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);
const ProductTabRight = lazy(() =>
  import("./pages/shop-product/ProductTabRight")
);
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() =>
  import("./pages/shop-product/ProductFixedImage")
);

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogRightSidebar = lazy(() => import("./pages/blog/BlogRightSidebar"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
);

// other pages
const About = lazy(() => import("./pages/other/About"));
const AboutEkki = lazy(() => import("./pages/other/AboutEkki"));
const ContactUs = lazy(() => import("./pages/other/Contact"));
const ContactUsSuccess = lazy(() => import("./pages/contact-us/ContactUsSuccess"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

const PaymentVerification = lazy(() => import("./pages/other/PaymentVerification"));
const PayOnDeliveryInvoice = lazy(() => import("./pages/other/PayOnDeliveryInvoice"));
// const NotFound = lazy(() => import("./pages/other/NotFound"));
const ShopNotFound = lazy(() => import("./pages/other/ShopNotFound"));
const PageNotFound = lazy(() => import("./pages/other/PageNotFound"));

const googleOneTapOptions = {
  client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID, // compulsory
  auto_select: false, // optional
  cancel_on_tap_outside: false, // optional
  context: 'signin', // optional,
  cookie_domain: '.ekki.com.ng' // this might enable all subdomain or wildcard subdomains to be able to use it
}

export default function App(){  
  const dispatch = useDispatch();
  const user = useSelector((state)=> state?.authData?.user);
  const [shop, setShop] = useState(useSelector((state)=> state?.shop));
  const [isShopSearched, setIsShopSearched] = useState(false);
  
  useEffect(()=>{   
    // Set-Cookie: name=value; Domain=.yourdomain.com; SameSite=None; Secure
    document.cookie = "token=yourTokenValue; Domain=.ekki.com.ng; Path=/; Secure; SameSite=None";

    const loadShop = async () => {
      try{
        const res = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}public/shop/by-url?domain=${process.env.NODE_ENV === 'development' ? 'ekki.com.ng' : ''}`);
        // const res = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}public/shop/by-url?domain=${process.env.NODE_ENV === 'development' ? 'distinction-tech.ekki.com.ng' : ''}`);
        setIsShopSearched(true);

        if(res.status === +process.env.REACT_APP_RECORD_NOT_FOUND){
          //console.log('no shop found');
        }else{
          const { error, data } = res.data;
          if(!error && typeof data === 'object' && data.slug){
            setShop(data); 
            dispatch(fetchedShop(data));
            localStorage.setItem('shop', JSON.stringify(data));
          }
        }
      }catch(err){
        setIsShopSearched(true);
        console.error(err);
      }
    }
    const loadSliders = async () => {
      try{
        const res = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}public/slider`);
        
        if(res.status !== +process.env.REACT_APP_RECORD_NOT_FOUND){
          const { error, data } = res.data;
          if(!error && Array.isArray(data)){
            dispatch(sliderReducer(data));
          }
        }
      }catch(err){
        console.error(err);
      }
    }
    
    if(!shop){
      (async ()=>{
        await loadShop();
        console.log('b')
      })();
      
    }else{
      loadSliders();
      incrementViewCount(shop); 
      if(!user){
        googleOneTap(googleOneTapOptions, async (response)=> {    // google-one-tap  
          console.log('response', response);  
          try{
              const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}auth/google-login`,
                {
                  token: response.credential
                }
              );
                  
              if(res.status === +process.env.REACT_APP_RECORD_NOT_FOUND){
                alert('Invalid details supplied. If you\'d like to register kindly use the register form');
              }else{
                const { error, data } = res.data;
            
                if(!error){    
                  localStorage.setItem('accessToken', data.accessToken);  
                  dispatch(fetchedLoggedInUser(data));            
                }
              }
          }catch(err){
            console.error(err);
          }
        });
      }     
    }        

      //   props.dispatch(
  //     loadLanguages({
  //       languages: {
  //         en: require("./translations/english.json"),
  //         fn: require("./translations/french.json"),
  //         de: require("./translations/germany.json")
  //       }
  //     })
  //   );

  },[user, dispatch, shop]);


  const incrementViewCount = async (shop) => {
    try{
      await axios.post(`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}public/counter/create`,{
        shopSlug: shop ? shop.slug : '',
        itemType: 'shop',
        itemSlug: shop ? shop.slug : '',
        operation: 'view'
      });
    }catch(err){
      console.error(err);
    }
  }

  if(!shop && isShopSearched){
    // display loading
    return <Suspense fallback={<div>loading...</div>}>
      <ShopNotFound />
    </Suspense>
  }else{
    return (
      <>
      <Component />
      <ToastProvider placement="bottom-left">
        <BreadcrumbsProvider>
          <Router>
            <ScrollToTop>
              <Suspense
                fallback={
                  <div className="flone-preloader-wrapper">
                    <div className="flone-preloader">
                      <span>...</span>
                      <span>...</span>
                    </div>
                  </div>
                }
              >
                <Routes>
                  <Route
                    exact
                    path={"/"}
                    element={<HomeFashion />}
                  />  
                  {/* Homepages */}
                  <Route
                    path={"/home-fashion"}
                    element={<HomeFashion />}
                  />
                  <Route
                    path={"/home-fashion-two"}
                    element={<HomeFashionTwo />}
                  />
                  <Route
                    path={"/home-fashion-three"}
                    element={<HomeFashionThree />}
                  />
                  <Route
                    path={"/home-fashion-four"}
                    element={<HomeFashionFour />}
                  />
                  <Route
                    path={"/home-fashion-five"}
                    element={<HomeFashionFive />}
                  />
                  <Route
                    path={"/home-fashion-six"}
                    element={<HomeFashionSix />}
                  />
                  <Route
                    path={"/home-fashion-seven"}
                    element={<HomeFashionSeven />}
                  />
                  <Route
                    path={"/home-fashion-eight"}  
                    element={<HomeFashionEight />}
                  />
                  <Route
                    path={"/home-kids-fashion"}
                    element={<HomeKidsFashion />}
                  />
                  <Route
                    path={"/home-cosmetics"}
                    element={<HomeCosmetics />}
                  />
                  <Route
                    path={"/home-furniture"}
                    element={<HomeFurniture />}
                  />
                  <Route
                    path={"/home-furniture-two"}
                    element={<HomeFurnitureTwo />}
                  />
                  <Route
                    path={"/home-furniture-three"}
                    element={<HomeFurnitureThree />}
                  />
                  <Route
                    path={"/home-furniture-four"}
                    element={<HomeFurnitureFour />}
                  />
                  <Route
                    path={"/home-furniture-five"}
                    element={<HomeFurnitureFive />}
                  />
                  <Route
                    path={"/home-furniture-six"}
                    element={<HomeFurnitureSix />}
                  />
                  <Route
                    path={"/home-furniture-seven"}
                    element={<HomeFurnitureSeven />}
                  />
                  <Route
                    path={"/home-electronics"}
                    element={<HomeElectronics />}
                  />
                  <Route
                    path={"/home-electronics-two"}
                    element={<HomeElectronicsTwo />}
                  />
                  <Route
                    path={"/home-electronics-three"}
                    element={<HomeElectronicsThree />}
                  />
                  <Route
                    path={"/home-book-store"}
                    element={<HomeBookStore />}
                  />
                  <Route
                    path={"/home-book-store-two"}
                    element={<HomeBookStoreTwo />}
                  />
                  <Route
                    path={"/home-plants"}
                    element={<HomePlants />}
                  />
                  <Route
                    path={"/home-flower-shop"}
                    element={<HomeFlowerShop />}
                  />
                  <Route
                    path={"/home-flower-shop-two"}
                    element={<HomeFlowerShopTwo />}
                  />
                  <Route
                    path={"/home-organic-food"}
                    element={<HomeOrganicFood />}
                  />
                  <Route
                    path={"/home-organic-food-two"}
                    element={<HomeOrganicFoodTwo />}
                  />
                  <Route
                    path={"/home-onepage-scroll"}
                    element={<HomeOnepageScroll />}
                  />
                  <Route
                    path={"/home-grid-banner"}
                    element={<HomeGridBanner />}
                  />
                  <Route
                    path={"/home-auto-parts"}
                    element={<HomeAutoParts />}
                  />
                  <Route
                    path={"/home-cake-shop"}
                    element={<HomeCakeShop />}
                  />
                  <Route
                    path={"/home-handmade"}
                    element={<HomeHandmade />}
                  />
                  <Route
                    path={"/home-pet-food"}
                    element={<HomePetFood />}
                  />
                  <Route
                    path={"/home-medical-equipment"}
                    element={<HomeMedicalEquipment />}
                  />
                  <Route
                    path={"/home-christmas"}
                    element={<HomeChristmas />}
                  />
                  <Route
                    path={"/home-black-friday"}
                    element={<HomeBlackFriday />}
                  />
                  <Route
                    path={"/home-black-friday-two"}
                    element={<HomeBlackFridayTwo />}
                  />
                  <Route
                    path={"/home-valentines-day"}
                    element={<HomeValentinesDay />}
                  />
  
                  {/* Shop pages */}
                  <Route
                    path={"/shop-grid-standard"}
                    element={<ShopGridStandard />}
                  />
                  <Route
                    path={"/shop-grid-filter"}
                    element={<ShopGridFilter />}
                  />
                  <Route
                    path={"/shop-grid-two-column"}
                    element={<ShopGridTwoColumn />}
                  />
                  <Route
                    path={"/shop-grid-no-sidebar"}
                    element={<ShopGridNoSidebar />}
                  />
                  <Route
                    path={"/shop-grid-full-width"}
                    element={<ShopGridFullWidth />}
                  />
                  <Route
                    path={"/shop-grid-right-sidebar"}
                    element={<ShopGridRightSidebar />}
                  />
                  <Route
                    path={"/shop-list-standard"}
                    element={<ShopListStandard />}
                  />
                  <Route
                    path={"/shop-list-full-width"}
                    element={<ShopListFullWidth />}
                  />
                  <Route
                    path={"/shop-list-two-column"}
                    element={<ShopListTwoColumn />}
                  />
  
                  {/* Shop product pages */}
                  <Route
                    path={"/product/:slug"}
                    element={<Product />}
                  />
                  <Route
                    path={"/product-tab-left/:slug"}
                    element={<ProductTabLeft />}
                  />
                  <Route
                    path={"/product-tab-right/:slug"}
                    element={<ProductTabRight />}
                  />
                  <Route
                    path={"/product-sticky/:slug"}
                    element={<ProductSticky />}
                  />
                  <Route
                    path={"/product-slider/:slug"}
                    element={<ProductSlider />}
                  />
                  <Route
                    path={"/product-fixed-image/:slug"}
                    element={<ProductFixedImage />}
                  />
  
                  {/* Blog pages */}
                  <Route
                    path={"/blog-standard"}
                    element={<BlogStandard />}
                  />
                  <Route
                    path={"/blog-no-sidebar"}
                    element={<BlogNoSidebar />}
                  />
                  <Route
                    path={"/blog-right-sidebar"}
                    element={<BlogRightSidebar />}
                  />
                  <Route
                    path={"/blog-details-standard"}
                    element={<BlogDetailsStandard />}
                  />
  
                  {/* Other pages */}
                  <Route
                    path={"/about"}
                    element={<About />}
                  />
                  <Route
                    path={"/about-ekki"}
                    element={<AboutEkki />}
                  />
                  <Route
                    path={"/contact"}
                    element={<ContactUs />}
                  />
                  <Route
                    path={"/contact/success"}
                    element={<ContactUsSuccess />}
                  />                
                  <Route
                    path={"/my-account"}
                    element={<MyAccount />}
                  />
                  <Route
                    path={"/login-register"}
                    element={<LoginRegister />}
                  />
  
                  <Route
                    path={"/cart"}
                    element={<Cart />}
                  />
                  <Route
                    path={"/wishlist"}
                    element={<Wishlist />}
                  />
                  <Route
                    path={"/compare"}
                    element={<Compare />}
                  />
                  <Route
                    path={"/checkout"}
                    element={<Checkout />}
                  />
                  <Route
                    path={"/payment/verify"}
                    element={<PaymentVerification />}
                  />
  
                  <Route
                    path={"/order/invoice"}
                    element={<PayOnDeliveryInvoice />}
                  />
  
                  <Route
                    path={"/search"}
                    element={<Search />}
                  />

                  <Route
                    path={"/cookie-policy"}
                    element={<CookiePolicy />}
                  />
  
                  <Route
                    path={"/not-found"}
                    // element={<NotFound />}
                    element={<PageNotFound />}
                  />
  
                  {/* <Route exact element={<NotFound />} /> */}
                  <Route exact element={<PageNotFound />} />
                </Routes>
              </Suspense>
            </ScrollToTop>
          </Router>
        </BreadcrumbsProvider>
        <CookieConsent />
      </ToastProvider>
      </>
    );
  }
};










// import React, { useState, useEffect } from 'react';
// import { Helmet } from 'react-helmet';

// const DynamicMetaPage = ({ title, description, imageUrl }) => {
//   const [pageTitle, setPageTitle] = useState(title);
//   const [pageDescription, setPageDescription] = useState(description);
//   const [pageImageUrl, setPageImageUrl] = useState(imageUrl);

//   // Update meta tags when props change
//   useEffect(() => {
//     setPageTitle(title);
//     setPageDescription(description);
//     setPageImageUrl(imageUrl);
//   }, [title, description, imageUrl]);

//   return (
//     <div>
//       {/* React Helmet for dynamically updating the page title and meta tags */}
//       <Helmet>
//         <title>{pageTitle}</title>
//         <meta name="description" content={pageDescription} />

//         {/* Open Graph Meta Tags for Social Media Sharing */}
//         <meta property="og:title" content={pageTitle} />
//         <meta property="og:description" content={pageDescription} />
//         <meta property="og:image" content={pageImageUrl} />
//         <meta property="og:url" content={window.location.href} />
//       </Helmet>

//       <h1>{pageTitle}</h1>
//       <p>{pageDescription}</p>
//       <img src={pageImageUrl} alt="Page" style={{ width: '300px' }} />
//     </div>
//   );
// };

// // Example usage
// const App = () => {
//   const pageData = {
//     title: 'Dynamic Title Example',
//     description: 'This is a dynamically set description for this page.',
//     imageUrl: 'https://yourwebsite.com/path-to-image.jpg',
//   };

//   // return <DynamicMetaPage {...pageData} />;
//   return <DynamicMetaPage title={`Hello Title`} description={`Hello Desc`} />
// };
// export default App;
