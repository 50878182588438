import { configureStore, combineReducers } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import counterReducer from './counterSlice';
import productsReducer from './productsSlice';
import shopReducer from './shopSlice';
import authReducer from './authSlice';
import cartReducer from './cartSlice';
import wishListReducer from './wishListSlice';
import compareListReducer from './compareListSlice';
import currencyReducer from './currencySlice';
import sliderReducer from './sliderSlice';

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  // multilanguage: createMultilanguageReducer({ currentLanguageCode: 'en' }),
  // currencyData: currencyReducer,
  // productData: productReducer,
  // shopData2: shopReducer,
  // cartData: cartReducer,
  // wishlistData: wishlistReducer,
  // compareData: compareReducer,
  // authData: authReducer,

  counter: counterReducer,
  products: productsReducer,
  shop: shopReducer,
  authData: authReducer,
  cartData: cartReducer,
  wishlistData: wishListReducer,
  compareData: compareListReducer,
  currencyData: currencyReducer,
  sliders: sliderReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware()
    .concat([thunk])
})

export const persistor = persistStore(store)