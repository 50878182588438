import { createSlice } from '@reduxjs/toolkit'

export const AUTH_SLICE_NAME = 'auth'

const initialState = {
    user: '',
    // user:null,
    isLogin: false,
    error: true,
    message: ''
  }
  
export const authSlice = createSlice({
  name: AUTH_SLICE_NAME,
  initialState,
  reducers: {
    fetchedLoggedInUser: (state = initialState, action) => {
    return {
        ...state,
        ...action.payload,
        isLogin: true,
        error:false,
        message:''
      }
    },
    logoutUser: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      // return {
      //   ...state,
      //   user:null,
      //   // user:null,
      //   isLogin: false,
      //   message:'',
      //   // error: true
      // }
      return initialState;
    },
  },
})

// Action creators are generated for each case reducer function
export const { fetchedLoggedInUser, logoutUser } = authSlice.actions

export default authSlice.reducer