// import uuid from "uuid/v4";
import { createSlice } from '@reduxjs/toolkit'

export const COMPARE_SLICE_NAME = 'compare';
const initialState = [];
  
export const compareListSlice = createSlice({
  name: COMPARE_SLICE_NAME,
  initialState,
  reducers: {
    compareReducer: (state = initialState, action) => {
      const compareItems = state,
      product = action.payload;
  
      const compareItem = compareItems.filter(item => item.id === product.id)[0];
      if (compareItem === undefined) {
        return [...compareItems, product];
      } else {
        return compareItems;
      }  
              
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
    },
    clearCompareList: (state, action) => {
      const compareItems = state,
      product = action.payload;

      
      const remainingItems = (compareItems, product) =>
        compareItems.filter(compareItem => compareItem.id !== product.id);
      return remainingItems(compareItems, product);


        // console.log('rem cart:', type, payload)
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
    },
    // clearAllCompareList: (state) => {    
    //     return state.filter(item => {
    //         return false;
    //     });    
    //     // console.log('rem cart:', type, payload)
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    // },
  },
})

// Action creators are generated for each case reducer function
export const { compareReducer, clearCompareList } = compareListSlice.actions

export default compareListSlice.reducer