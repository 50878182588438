import { createSlice } from '@reduxjs/toolkit'

export const CURRENCY_SLICE_NAME = 'currency';
const initialState = {
    // currencySymbol: "$",
    // currencyName: "USD",
    // currencyRate: 1

    currencySymbol: "₦",
    currencyName: "NGN",
    currencyRate: 1


  };
  
export const currencySlice = createSlice({
  name: CURRENCY_SLICE_NAME,
  initialState,
  reducers: {
    setCurrencyReducer: (state = initialState, action) => {    
        console.log('cur slice:', action)
        const currencyName = action.payload.currencyName;
    
        if (currencyName === "USD") {
            return {
            ...state,
            currencySymbol: "$",
            currencyRate: action.payload.currencyRate,
            currencyName
            };
        }
        if (currencyName === "NGN") {
            return {
            ...state,
            currencySymbol: "₦",
            currencyRate: action.payload.currencyRate,
            currencyName
            };
        }
    
        if (currencyName === "EUR") {
            return {
            ...state,
            currencySymbol: "€",
            currencyRate: action.payload.currencyRate,
            currencyName
            };
        }
        if (currencyName === "GBP") {
            return {
            ...state,
            currencySymbol: "£",
            currencyRate: action.payload.currencyRate,
            currencyName
            };
        }
    },

    
  },
})

// Action creators are generated for each case reducer function
export const { setCurrencyReducer } = currencySlice.actions

export default currencySlice.reducer