import uuid from "uuid/v4";
import { createSlice } from '@reduxjs/toolkit'
export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";

export const CART_SLICE_NAME = 'cart';
const initialState = [];
  
export const cartSlice = createSlice({
  name: CART_SLICE_NAME,
  initialState,
  reducers: {
    cartReducer: (state = initialState, {payload}) => { 
        const product = payload;
        
          const cartItem = state.filter(item => item.id === product.id)[0];

          if(cartItem){
            cartItem.quantity = cartItem.quantity+1;
            return state;
          }else{
            return [
              ...state,
              {
                ...product,
                quantity: 1,
                cartItemId: uuid()
              }
            ];
          }      
        
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
    //   return {
    //     ...state,
    //     cart: payload,
    //   }
    },
    removeFromCart: (state, {type, payload}) => {
      const cartItems = state,
      product = payload;
  
      const remainingItems = (cartItems, product) =>
        cartItems.filter(cartItem => cartItem.cartItemId !== product.cartItemId);
      return remainingItems(cartItems, product);
    },
    clearCart: () => {
      // console.log('clear:,')
      return []
    },
    decreaseQuantity: (state, action) => {
      const cartItems = state,
      product = action.payload;
  
      if (product.quantity === 1) {
        const remainingItems = (cartItems, product) =>
          cartItems.filter(
            cartItem => cartItem.cartItemId !== product.cartItemId
          );
        return remainingItems(cartItems, product);
      } else {
        return cartItems.map(item =>
          item.cartItemId === product.cartItemId
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
      }    },

  }
});

// Action creators are generated for each case reducer function
export const { cartReducer, removeFromCart, clearCart, decreaseQuantity } = cartSlice.actions

export default cartSlice.reducer