// import uuid from "uuid/v4";
import { createSlice } from '@reduxjs/toolkit'
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const DELETE_FROM_WISHLIST = "DELETE_FROM_WISHLIST";
export const DELETE_ALL_FROM_WISHLIST = "DELETE_ALL_FROM_WISHLIST";

export const WISH_LIST_SLICE_NAME = 'wish_list';
const initialState = [];
  
export const wishListSlice = createSlice({
  name: WISH_LIST_SLICE_NAME,
  initialState,
  reducers: {
    wishListReducer: (state = initialState, {type, payload}) => {
        // console.log('wishlist slice:', type, payload, 's:', state);
        // const wishlistItems = state,
        const product = payload;
    
        const wishlistItem = state.filter(
          item => item.id === product.id
        )[0];
        if (wishlistItem === undefined) {
          return [...state, product];
        } else {
          return state;
        }    
                
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
    },
    clearWishList: (state, action) => {
        const product = action.payload;

        const remainingItems = (state, product) =>
            state.filter(wishlistItem => wishlistItem.id !== product.id);
        return remainingItems(state, product);

        // console.log('rem cart:', type, payload)
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
    },
    clearAllWishList: (state) => {    
        return state.filter(item => {
            return false;
        });    
        // console.log('rem cart:', type, payload)
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
    },
  },
})

// Action creators are generated for each case reducer function
export const { wishListReducer, clearWishList, clearAllWishList } = wishListSlice.actions

export default wishListSlice.reducer