import React, { useState, useEffect } from 'react';
import './CookieConsent.css'; // Create a separate CSS file for styling
// import { Link } from 'react-router-dom';

export default function CookieConsent () {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (!cookiesAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="cookie-banner">
        <div className="cookie-content text-center">
          <div className="cookie-text text-light px-5">
            We use cookies to enhance your experience. By continuing to browse, you agree to our{' '}
            <a href={`${window.location.origin}/cookie-policy`} className="cookie-link" target="_blank" rel="noopener noreferrer">
              cookie policy
            </a>.
 
            {/* <Link 
              to={`/cookie policy`} 
              // className="cookie-link" 
              // target="_blank" 
              // rel="noopener noreferrer"
            >
              cookie policy
            </Link> */}
            
          </div>
          <button className="cookie-button" onClick={handleAcceptCookies}>
            Accept
          </button>
        </div>
      </div>
    )
  );
};